import React, { useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import Input from "../components/Form/Input"
import Textarea from "../components/Form/Textarea"
import Checkbox from "../components/Form/Checkbox"
import RadioButton from "../components/Form/Radio"
import { useAxiosClient } from "../service/useAxiosClient"
import Message from "../components/Message"
import { Fragment } from "react"
import TitlePage from "../components/TitlePage"

export default function Contact({ location }) {
  const [infoToSave, setInfoToSave] = useState({})

  const [statusCode, setStatusCode] = useState(null)

  const [isChecked, setIsChecked] = useState(false)

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Contacto - Contáctenos" },
  ]

  const radios = [
    { label: "Soy centro de formación y deseo acreditarme", value: 1 },
    { label: "Busco información sobre exámenes EFPA España", value: 2 },
    { label: "Quiero ser socio corporativo", value: 3 },
    { label: "Soy miembro certificado de EFPA España", value: 4 },
    { label: "Otros", value: 5 },
  ]

  const handleChangeInformation = ({ name, value }) => {
    setInfoToSave(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleCheckbox = ({ checked }) => {
    setIsChecked(checked)
  }

  const [, triggerSaveData] = useAxiosClient(
    {},
    {
      manual: true,
    }
  )

  const sendToAPi = async e => {
    e.preventDefault()
    if (!isChecked && statusCode) {
      if (infoToSave.email && infoToSave.name && infoToSave.comments) {
        setStatusCode(201)
      }
      return
    }
    try {
      await triggerSaveData({
        url: `/emails/web/contact/`,
        method: "post",
        data: {
          ...infoToSave,
        },
      })
      setStatusCode(200)
    } catch (error) {
      const {
        response: { status },
      } = error
      setStatusCode(status)
    }
  }

  const renderMessage = () => {
    if (!statusCode) return

    let message = ""
    let type = ""
    if (!isChecked) {
      message = `${message}
      Debe aceptar la política de protección de datos y advertencias legales.`
      type = "error"
    }
    if (statusCode === 400 && !message) {
      message = "Por favor, rellene todos los campos obligatorios."
      type = "error"
    }
    if (statusCode > 400 && !message) {
      message =
        "Ha ocurrido un error durante el envio. Por favor, inténtenlo más tarde o póngase en contacto con nosotros por otros medios. Muchas gracias."
      type = "error"
    }
    if (statusCode === 200 && !message) {
      message =
        "Sus datos se han enviado correctamente. Nos pondremos en contacto lo antes posible. Muchas gracias."
      type = "success"
    }
    return <Message text={message} type={type} />
  }

  const renderContent = () => {
    if (statusCode === 200) {
      return <Fragment></Fragment>
    }
    return (
      <form onSubmit={sendToAPi}>
        <Input
          controlClassName={`Contact-input ${
            statusCode === 400 ? "danger" : ""
          }`}
          className="Contact-label"
          label="Nombre y Apellidos (*)"
          onChange={handleChangeInformation}
          name="name"
          value={infoToSave?.name}
        />
        <Input
          controlClassName="Contact-input"
          label="Dirección particular o profesional"
          className="Contact-label"
          onChange={handleChangeInformation}
          name="address"
          value={infoToSave?.address}
        />
        <Input
          controlClassName="Contact-input"
          className="Contact-label"
          label="Código Postal"
          onChange={handleChangeInformation}
          name="postal_code"
          value={infoToSave?.postal_code}
        />
        <Input
          controlClassName="Contact-input"
          className="Contact-label"
          onChange={handleChangeInformation}
          label="Población"
          name="town"
          value={infoToSave?.town}
        />
        <Input
          controlClassName="Contact-input"
          className="Contact-label"
          onChange={handleChangeInformation}
          label="Teléfono"
          name="phone"
          value={infoToSave?.phone}
        />
        <Input
          controlClassName="Contact-input"
          className="Contact-label"
          label="Móvil"
          onChange={handleChangeInformation}
          name="mobile"
          value={infoToSave?.mobile}
        />
        <Input
          controlClassName={`Contact-input ${
            statusCode === 400 ? "danger" : ""
          }`}
          className="Contact-label"
          label="E-mail(*)"
          onChange={handleChangeInformation}
          name="email"
          value={infoToSave?.email}
        />
        <Input
          controlClassName="Contact-input"
          className="Contact-label"
          label="Empresa"
          onChange={handleChangeInformation}
          name="company"
          value={infoToSave?.company}
        />
        <div className="Contact-wrapper">
          <RadioButton
            options={radios}
            id="radios"
            name="Reason"
            onChange={handleChangeInformation}
          />
        </div>
        <Textarea
          onChange={handleChangeInformation}
          labelClass="Contact-textarea"
          label="Déjanos tu comentario (*) :"
          name="comments"
          value={infoToSave?.comments}
          className={`${statusCode === 400 ? "danger" : ""}`}
        />
        <Checkbox onChange={handleCheckbox}>
          He leído y acepto la{" "}
          <a href="/politica-privacidad" target="_blank">
            política de protección de datos
          </a>{" "}
          y advertencias legales aplicables a esta comunicación.
        </Checkbox>
        <button type="submit" className="Contact-btn">
          Enviar
        </button>
      </form>
    )
  }

  return (
    <Layout>
      <TitlePage title="Contacte con EFPA España" url={location.pathname} />
      <Hero image="/imgs/contact-hero.jpg" text="Contacto" />
      <div className="container950">
        <div className="Contact">
          <Breadcrumb path={path} />
          <TitleBlock title="Contáctenos" icon="fas fa-at" />
          {renderMessage()}
          {renderContent()}
        </div>
      </div>
    </Layout>
  )
}
