import React, { Fragment, useEffect, useState } from "react"
import propTypes from "prop-types"

export default function Textarea(props) {
  const { className, labelClass, label, name, id, onChange, value } = props

  const [inputInfo, setInputInfo] = useState(null)

  const handleChange = e => {
    if (onChange && typeof onChange === "function") {
      onChange(e.target)
    }

    setInputInfo(e.target.value)
  }

  useEffect(() => {
    setInputInfo(value)
  }, [value])
  return (
    <Fragment>
      <label className={`Textarea-label ${labelClass}`} htmlFor="">
        {label}
      </label>
      <textarea
        onChange={handleChange}
        className={`Textarea ${className}`}
        name={name}
        id={id}
        value={inputInfo}
      ></textarea>
    </Fragment>
  )
}

Textarea.propTypes = {
  className: propTypes.string,
  labelClass: propTypes.string,
  label: propTypes.string,
  name: propTypes.string,
  id: propTypes.string,
}

Textarea.defaultProps = {
  className: "",
  labelClass: "",
  label: "",
  name: "",
  id: "",
}
